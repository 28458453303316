import React from 'react';
import { IntlShape } from 'react-intl';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { ILabelValuePair, IAddressInputFields, IAddressFieldInfo } from '@whitelabel/helpers/types';
import StyledLTRblock from '../styled/StyledLTRblock';
import TransformLink from '../components/TransformLink';
import { IFNOLAddress, IDynamicAddress } from './types';
import enStateList from './states/state-en.json';

export const transformLinkFactory = ({ locale, node }: { locale: string; node: any }) => {
  const { type, name, attribs, children } = node;

  if (typeof window === 'undefined') {
    return undefined;
  }

  const {
    location: { pathname },
  } = window;

  if (type !== 'tag' || name !== 'a') {
    return undefined;
  }

  if (!attribs.href) {
    const isNonEnPDS = locale !== 'en' && locale !== 'en-us' && pathname.includes('/pds/');
    if (isNonEnPDS) {
      return undefined;
    }
    const error = new Error('HTML a tag is missing a valid href attribute');
    captureExceptionWithFullStory(error);
    return undefined;
  }

  if (attribs.href.startsWith('tel:')) {
    return (
      <a href={attribs.href} key={`${children[0].data}-${attribs.href}`}>
        <StyledLTRblock>{children[0].data}</StyledLTRblock>
      </a>
    );
  }

  const { href, target } = attribs || {};
  let attribsHref = href as string;

  if (attribsHref.startsWith('www.')) {
    attribsHref = `https://${attribsHref}`;
  }

  let hrefURL;
  try {
    hrefURL = new URL(attribsHref, window.location.origin);
  } catch (error) {
    captureExceptionWithFullStory(new Error(`${attribsHref} provided is not a valid url`));
    return undefined;
  }

  if (
    hrefURL.hostname === window.location.hostname ||
    `www.${hrefURL.hostname}` === window.location.hostname ||
    hrefURL.hostname.includes('xcover.com')
  ) {
    const relativeLink = `${hrefURL.pathname}${hrefURL.search}${hrefURL.hash}`;
    return (
      <TransformLink key={`${children}-${relativeLink}`} relativeLink={relativeLink} target={target}>
        {children}
      </TransformLink>
    );
  }

  return undefined;
};

export const transformLink = (intl: IntlShape) => (node: any) =>
  transformLinkFactory({
    locale: intl.locale,
    node,
  });

export const extractAddress = (addressComponents?: google.maps.GeocoderAddressComponent[]): IFNOLAddress => {
  const addressObj: IFNOLAddress = {};

  const addressMap = {
    street_number: 'streetNumber',
    route: 'streetName',
    administrative_area_level_1: 'region',
    country: 'country',
    postal_code: 'postcode',
  };

  addressComponents?.forEach(({ short_name: shortName, long_name: longName, types }) => {
    const addComp = types.find((type) => Object.keys(addressMap).includes(type)) as keyof typeof addressMap;
    if (addComp) {
      addressObj[addressMap[addComp] as keyof IFNOLAddress] = addComp === 'route' ? longName : shortName;
    }
  });

  return addressObj;
};

export interface LocalizedState {
  name: string;
  isoCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}

export const translatedStateLocales = ['fr', 'it', 'de', 'nl', 'es'];

export async function getLocalizedStates(locale: string): Promise<LocalizedState[]> {
  return new Promise((resolve, reject) => {
    import(`./states/state-${locale}`)
      .then((states: { default: { states: LocalizedState[] } }) => states.default.states)
      .then(resolve)
      .catch((error: any) => reject(error));
  });
}

export const getStatesOfCountry = (
  countryCode?: string,
  locale?: string | string[],
  localizedStateList?: LocalizedState[],
): ILabelValuePair[] => {
  if (!countryCode) return [];
  let states = (localizedStateList ?? enStateList).filter((value) => value.countryCode === countryCode);
  // If the states for a requested country are not included in the localized list, then just return english
  if (states.length === 0) states = enStateList.filter((value) => value.countryCode === countryCode);

  return states
    .map(({ name, isoCode }) => ({ label: name, value: isoCode }))
    .sort((a, b) => a.label.localeCompare(b.label, locale));
};

export const getDynamicAddress = (
  googleAddressValue: IFNOLAddress,
  nameList?: IAddressFieldInfo<string>,
): IDynamicAddress => {
  const addressKeys = ['address1', 'address2', 'city', 'region', 'postcode', 'country'];

  return addressKeys.reduce((acc, field) => {
    const addressKey = nameList?.[field as keyof typeof nameList];
    const addressValue = googleAddressValue[field as keyof typeof googleAddressValue];

    if (addressKey) {
      return { ...acc, [addressKey]: addressValue };
    }
    return { ...acc, [field]: addressValue };
  }, {});
};

export const getDynamicFieldValue = (
  key: string,
  nameList?: IAddressFieldInfo<string>,
  inputFieldValue?: IAddressInputFields,
) => {
  if (nameList) {
    const newKey = nameList[key as keyof typeof nameList] as keyof IAddressInputFields;
    return inputFieldValue?.[newKey] ?? '';
  }
  return inputFieldValue?.[key as keyof IAddressInputFields] ?? '';
};
